export const DISPLAY_ALERT = 'SHOW_ALERT';
export const CLEAR_ALERT = 'CLEAR_ALERT';

export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';

export const HANDLE_CHANGE = 'HANDLE_CHANGE';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const CHANGE_PAGE = 'CHANGE_PAGE';

export const SET_EDIT_PRODUCT = 'SET_EDIT_PRODUCT';
export const SET_EDIT_CATEGORY = 'SET_EDIT_CATEGORY';

export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS';

export const SET_LOADING = 'SET_LOADING';

export const HANDLE_SHOW_MODAL = 'HANDLE_SHOW_MODAL';
export const HANDLE_CLOSE_MODAL = 'HANDLE_CLOSE_MODAL';

export const CHANGE_TYPE_PATH = 'CHANGE_TYPE_PATH';

export const UNSET_EDIT = 'UNSET_EDIT';
export const SET_ERROR = 'SET_ERROR';

export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_SINGLE_ORDER = 'GET_SINGLE_ORDER';

export const GET_REVIEWS_SUCCESS = 'GET_REVIEWS_SUCCESS';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const SHOW_STATS_SUCCESS = 'SHOW_STATS_SUCCESS';

export const GET_PARAMS_SUCCESS = 'GET_PARAMS_SUCCESS';
export const SET_EDIT_PARAM = 'SET_EDIT_PARAM';
